











import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import vSelect from 'vue-select';
import VxTimeline from "@/components/timeline/VxTimeline.vue";
import CallCenterInsertActivity from "@/views/customer/activity/CallCenterInsertActivity.vue";
import {TimeLine} from "@/models/customer/activity/TimeLine";
import {ActivityResult, OptionTransactionType} from "@/models/customer/activity/ActivityResult";
import {TransactionType} from "@/constants/TransactionType";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import {Common} from "@/helper/common";
// import {map, each, debounce} from 'lodash';
import map from 'lodash/map';
import each from 'lodash/each';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import {ActivityService} from "@/services/customer/ActivityService";
import {LoadResult} from "@/models/LoadResult";
import {CategoryService} from "@/store/category/CategoryService";
import {CategoryType} from "@/models/category/CategoryType";
import {Category} from "@/models/category/category";
import {SearchResult} from "@/models/SearchResult";
import {Getter} from "vuex-class";
import {ActionResult} from '@/models/ActionResult';
import moment from "moment";

@Component({
    components: {
        VxTimeline,
        vSelect,
        CallCenterInsertActivity
    }
})

export default class CallCenterCustomerActivityList extends Vue {
    @Ref('activityModal') activityQuickInsertComponent!: CallCenterInsertActivity;
    @Getter("oidcUser") oidcUser!: any;
    currentPage: number = 1;
    pageSize = 10; // default 10
    searchTerm = '';
    debounce: any = null;
    transactionTypes: OptionTransactionType[] = [];
    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    common = new Common();
    totalPages = 0;
    listType: any[] = [];
    timelineData: TimeLine[] = [];
    activitySelect: TimeLine;

    private activityService = new ActivityService();
    private categoryService = new CategoryService();

    constructor() {
        super();
        this.loadOptions.select = ['id', 'type', 'types', 'content', 'note', 'config', 'createdOnDate', 'modifiedOnDate',
            'fullName', 'dateTransaction', 'commentNo', 'attachmentCount', 'isFromTask', 'isRequiredCheckIn',
            'statusCheckIn', 'userId', 'isRemove'];

        this.loadOptions.sort = [new SortingInfo('createdOnDate', true)];
    }

    mounted() {
        this.search(1, true);
        setTimeout(() => {
            this.getCategoryType();
        }, 1500)
    }

    get currentUser() {
        return this.oidcUser;
    }

    @Watch('searchTerm')
    searchByTerm(value: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.rendererQuery();
        this.debounce = debounce(() => this.search(1, true), 500);
        this.debounce();
    }

    selectValue(values: OptionTransactionType[]) {
        this.transactionTypes = values;
        this.rendererQuery();
        this.search(1, true);
    }

    showAddForm() {
        this.activityQuickInsertComponent.show();
    }

    saveSuccess(value: any) {
        if (value) {
            this.search(1, true);
        }
    }

    loadMore() {
        this.search(this.currentPage + 1, false);
    }

    private async search(currentPage: number = 1, isResetData = false) {
        this.currentPage = currentPage;

        if (isResetData) {
            this.timelineData = [];
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.pageSize;
        this.loadOptions.take = this.pageSize;
        this.loadOptions.requireTotalCount = true;
        const customerId = this.$route.params.id;

        this.$vs.loading();

        this.activityService.search(this.loadOptions, customerId).finally(() => {
            this.$vs.loading.close();
        }).then((result: LoadResult) => {
            this.setTotalPage(result.totalCount);
            this.rendererData(result.data);
        })
    }

    private rendererQuery() {
        const paramsKeyword: any[] = [];
        if (this.searchTerm !== null && this.searchTerm !== null) {
            paramsKeyword.push(this.common.renderQuery('note', "contains", this.searchTerm));
            paramsKeyword.push('or');
            paramsKeyword.push(this.common.renderQuery('content', "contains", this.searchTerm));
            paramsKeyword.push('or');
            paramsKeyword.push(this.common.renderQuery('fullName', "contains", this.searchTerm));
        }

        const paramsType: any[] = [];
        if (this.transactionTypes && this.transactionTypes.length > 0) {
            each(this.transactionTypes, (item: OptionTransactionType, index: number) => {
                paramsType.push(this.common.renderQuery('type', "=", item.id.toString()))
                paramsType.push('or');
                paramsType.push(this.common.renderQuery('types', "contains", `"name":"${item.name}"`))
                if (index < this.transactionTypes.length - 1) {
                    paramsType.push('or');
                }
            })
        }

        const params: any = [];
        if (paramsKeyword && paramsKeyword.length > 0) {
            params.push(paramsKeyword);
        }

        if (paramsType && paramsType.length > 0) {
            if (paramsKeyword && paramsKeyword.length > 0) {
                params.push('and');
            }

            params.push(paramsType);
        }

        this.loadOptions.filterQuery = params && params.length > 0 ? JSON.stringify(params) : '';
    }

    removeActivity(item: TimeLine) {
        this.activitySelect = item;
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('ConfirmDelete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: this.$t('ConfirmDeleteText', {data: 'Nhật ký làm việc'}),
            accept: this.delete
        })
    }

    delete() {
        this.activityService.delete(this.activitySelect ? this.activitySelect.id : 0)
            .then((result: ActionResult<number>) => {
                if (result.code > 0) {
                    const index = findIndex(this.timelineData, (item: TimeLine)=>{
                        return item.id === this.activitySelect.id
                    })

                    this.$delete(this.timelineData, index);
                }

                this.$vs.notify({
                    color: result.code <= 0 ? 'danger' : 'success',
                    title: 'Thông báo',
                    text: result.message
                })
            })
    }

    private rendererData(data: ActivityResult[]) {
        // const timeLines: TimeLine[] = [];
        if (data && data.length > 0) {
            each(data, (item: ActivityResult) => {

                const timeLine = new TimeLine();
                timeLine.id = item.id;
                timeLine.isRemove = item.userId === this.currentUser.userId;
                timeLine.color = this.getColorActivity(item);
                timeLine.icon = this.getIconActivity(item);
                timeLine.desc = this.getContentActivity(item);
                timeLine.title = this.getTitleActivity(item);
                timeLine.time = moment(item.createdOnDate).format('DD-MM-YYYY HH:mm');
                timeLine.fullName = item.fullName;
                timeLine.isRemove = item.isRemove;
                this.timelineData.push(timeLine);
            })
        }
    }

    private getTitleActivity(item: ActivityResult) {
        if (item.types && item.types.length > 0) {
            try {
                const listType: any[] = JSON.parse(item.types);
                if (listType && listType.length > 0) {
                    const listTypeName = map(listType, 'name');
                    if (listTypeName.findIndex(x => x === undefined) >= 0) {
                        return map(listType, 'Name').join(', ');
                    } else {
                        return listTypeName.join(', ');
                    }
                }
            } catch (e) {
                return new OptionTransactionType(item.type).name;
            }
        }

        return new OptionTransactionType(item.type).name;
    }

    private getContentActivity(item: ActivityResult) {
        if (item.type === TransactionType.SelfCreated) {
            return item.note.replace(/\r\n|\r|\n/g, '<br />');
        } else {
            let config = '';
            const content = '';
            const contentText: any[] = [];

            if (item.config && item.isFromTask) {
                try {
                    config = JSON.parse(item.config);
                } catch (e) {
                    console.log(e.message())
                }
                if (config) {
                    each(config, (conf: any, idx: number) => {
                        if (conf.CheckinAddress !== '' && conf.CheckinAddress !== null && conf.CheckinAddress !== undefined) {
                            item.note += `<br/>Checkin tại vị trí <span style="color: #6EBD47; font-weight:bold">${conf.CheckinAddress}</span>`;
                        }
                    });
                }
            }

            return item.note ? item.note : contentText.join('<br/>');
        }
    }

    private setTotalPage(totalRows: number) {
        if (totalRows == 0) {
            this.totalPages = 0;
            return 0;
        }

        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
            return 1;
        }

        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }

    private getColorActivity(item: ActivityResult) {
        switch (item.type) {
            case TransactionType.CustomerCallIn:
            case TransactionType.TelesalesCallOut:
                return 'danger';
            case TransactionType.SendEmailQuotation:
            case TransactionType.SendSMSQuotation:
            case TransactionType.SendOrder:
            case TransactionType.SendQuotationMemorandum:
            case TransactionType.SendQuotation:
                return 'warning';
            case TransactionType.SendMarketing:
                return 'primary';
            case TransactionType.SelfCreated:
                return 'primary';
            case TransactionType.TaskUpdateComplete:
            case TransactionType.TaskCheckIn:
                return 'warning';
            case TransactionType.TaskCheckOut:
                return 'success';
            default:
                return 'primary';
        }
    }

    private getIconActivity(item: ActivityResult) {
        switch (item.type) {
            case TransactionType.CustomerCallIn:
                return 'PhoneIncomingIcon';
            case TransactionType.TelesalesCallOut:
                return 'PhoneOutgoingIcon';
            case TransactionType.SendEmailQuotation:
                return 'MailIcon';
            case TransactionType.SendQuotationMemorandum:
            case TransactionType.SendOrder:
            case TransactionType.SendQuotation:
            case TransactionType.SendSMSQuotation:
                return 'SendIcon';
            case TransactionType.SendMarketing:
                return 'TvIcon'
            case TransactionType.SelfCreated:
                return 'PlusIcon';
            case TransactionType.TaskUpdateComplete:
                return 'CheckIcon';
            case TransactionType.TaskCheckIn:
            case TransactionType.TaskCheckOut:
                return 'MapPinIcon';
            default:
                return 'PlusIcon';
        }
    }

    private async getCategoryType() {
        this.categoryService.getByType(CategoryType.TransactionType).then((result: SearchResult<Category>) => {
            this.listType = map(result.items, (item: Category) => {
                return {id: item.idNumber, name: item.name ? item.name : ''}
            });
        })
    }
}
